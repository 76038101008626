<template>
    <div class="row">
        <div
            :class="{ 'd-none': !paymentComplete }"
            class="checkoutForm justify-content-center space-top-lg-1 col-lg-7 order-1 order-lg-0 col-12"
        >
            <slot name="postPurchaseView">
                <div class="px-4">
                    <h1 class="display-5 text-center">
                        We appreciate your purchase from Thriftax
                    </h1>
                    <p class="lead text-center text-body">
                        You can keep browsing the information you've entered
                        already.
                    </p>

                    <div class="row d-flex justify-content-center">
                        <div class="col-4">
                            <img
                                class="img-fluid"
                                :src="
                                    require('@/assets/svg/illustrations/flowers.svg')
                                "
                                alt="SVG Illustration"
                            />
                        </div>
                    </div>
                </div>
            </slot>
        </div>
        <div
            :class="{
                'd-none':
                    !paymentFieldsDisabled ||
                    paymentComplete ||
                    paymentFieldsLoading,
            }"
            class="checkoutForm justify-content-center space-top-lg-1 col-lg-7 order-1 order-lg-0 col-12"
        >
            <slot name="freeOrderView">
                <div class="px-4">
                    <h1 class="display-5 text-center">
                        Looks like your order is free
                    </h1>
                    <p class="lead text-center text-body">
                        Please click "Next" to continue
                    </p>

                    <div class="row d-flex justify-content-center">
                        <div class="col-4">
                            <img
                                class="img-fluid"
                                :src="
                                    require('@/assets/svg/illustrations/flowers.svg')
                                "
                                alt="SVG Illustration"
                            />
                        </div>
                    </div>
                </div>
            </slot>
        </div>
        <div
            v-if="paymentFieldsLoading"
            class="col-lg-7 order-1 order-lg-0 col-12 d-flex justify-content-center align-items-center"
        >
            <loading color="primary" :size="80" />
        </div>
        <div
            :class="{
                'd-none':
                    paymentFieldsDisabled ||
                    paymentComplete ||
                    paymentFieldsLoading,
            }"
            class="col-lg-7 order-1 order-lg-0 col-12 pr-lg-4"
        >
            <div
                class="btn-group btn-group-toggle btn-group-segment d-flex mb-7"
            >
                <label
                    class="btn py-1"
                    :class="paymentMethod == 'credit' ? 'active' : ''"
                >
                    <input type="checkbox" @click="paymentMethod = 'credit'" />
                    Credit Card
                </label>
                <label
                    class="btn py-1"
                    :class="paymentMethod == 'paypal' ? 'active' : ''"
                >
                    <input type="checkbox" @click="paymentMethod = 'paypal'" />
                    Paypal
                </label>
                <label
                    v-if="testingPayments"
                    class="btn py-1"
                    :class="paymentMethod == 'venmo' ? 'active' : ''"
                >
                    <input type="checkbox" @click="paymentMethod = 'venmo'" />
                    Venmo
                </label>
            </div>

            <error-card :errors="errors" />

            <form>
                <div
                    :class="{
                        'd-none': paymentMethod != 'credit',
                    }"
                >
                    <div class="input-margin-bottom">
                        <label class="input-label input-label-spacing"
                            >Credit Card Number</label
                        >
                        <div class="cardNum">
                            <div
                                id="creditCardNumber"
                                class="form-control custom-field py-0"
                                :class="creditError ? 'is-invalid' : ''"
                            ></div>
                        </div>
                        <div
                            class="row flex-row mt-0 mx-2 text-danger small"
                            v-if="creditError"
                        >
                            {{ creditError }}
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="p-0 m-0 fly-row-col-pr-md input-margin-bottom col-md-6 col-12">
                            <label class="input-label input-label-spacing"
                                >Expiry Date</label
                            >
                            <div
                                id="expiryDate"
                                class="form-control custom-field py-0"
                                :class="expiryError ? 'is-invalid' : ''"
                            ></div>
                            <span
                                class="row flex-row mt-0 ml-1 text-danger small"
                                v-if="expiryError"
                                >{{ expiryError }}</span
                            >
                        </div>
                        <div class="p-0 m-0 fly-row-col-pl-md input-margin-bottom col-md-6 col-12">
                            <label class="input-label input-label-spacing"
                                >CVV</label
                            >
                            <div
                                id="cvv"
                                class="form-control custom-field py-0"
                                :class="cvvError ? 'is-invalid' : ''"
                            ></div>
                            <span
                                class="row flex-row mt-0 ml-1 text-danger small"
                                v-if="cvvError"
                                >{{ cvvError }}</span
                            >
                        </div>
                    </div>
                    <div class="input-margin-bottom">
                        <label class="input-label input-label-spacing"
                            >Billing Address Postal / Zip Code</label
                        >
                        <div
                            id="postalCode"
                            class="form-control custom-field py-0"
                            :class="postalCodeError ? 'is-invalid' : ''"
                        ></div>
                        <span
                            class="row flex-row mt-0 ml-1 text-danger small"
                            v-if="postalCodeError"
                            >{{ postalCodeError }}</span
                        >
                    </div>

                    <div class="row d-flex justify-content-around px-md-8 my-5">
                        <div
                            class="col d-flex justify-content-center"
                        >
                            <img
                                class="payment"
                                :src="require('@/assets/visa logo.png')"
                                alt="Visa"
                            />
                        </div>
                        <div class="col d-flex justify-content-center">
                            <img
                                :src="require('@/assets/mastercard logo.png')"
                                class="payment"
                                alt="Master Card"
                            />
                        </div>
                        <div class="col d-flex justify-content-center">
                            <img
                                :src="require('@/assets/amex logo.png')"
                                class="payment"
                                alt="American Express"
                            />
                        </div>
                    </div>

                    <div class="d-flex justify-content-center mb-9">
                        <a
                            href="https://www.braintreegateway.com/merchants/2s74zvws8jky9syy/verified"
                            target="_blank"
                        >
                            <img
                                src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png"
                                width="280px"
                                height="44px"
                                border="0"
                            />
                        </a>
                    </div>
                </div>

                <div
                    :class="{
                        'd-none': paymentMethod != 'paypal',
                    }"
                >
                    <p class="text-center text-muted">
                        Complete your checkout in a separate window:
                    </p>
                    <span id="paypalButton"></span>
                </div>

                <div
                    :class="{
                        'd-none': paymentMethod != 'venmo',
                    }"
                >
                    <p class="text-center text-muted">
                        Complete your checkout in a separate window:
                    </p>
                    <div class="d-flex justify-content-center">
                        <button id="venmo-button" class="btn venmo_color">
                            <span class="text-light">Venmo</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-lg-5 order-0 order-lg-1 col-12 mb-7 mb-lg-0">
            <div class="card p-3 text-dark">
                <h2 class="h3 mt-1 mb-3">Purchase summary</h2>
                <div
                    class="border-top py-2"
                    v-for="item in discountableItems"
                    :key="item.price_id"
                >
                    <div class="d-flex justify-content-between py-1">
                        <p class="pb-0 mb-0">
                            {{ item.name }}
                        </p>
                        <p class="pb-0 mb-0">
                            <span v-if="item.amount"
                                >${{ item.amount.toFixed(2) }}</span
                            ><span v-else>FREE</span>
                        </p>
                    </div>
                </div>
                <div
                    class="mt-2"
                    v-if="promos.length || nonDiscountableItems.length"
                >
                    <div class="d-flex justify-content-between">
                        <p class="pb-2 mb-0 font-weight-bold lead">
                            <span v-if="nonDiscountableItems.length"
                                >Subtotal</span
                            >
                            <span v-else>Total</span>
                        </p>
                        <p
                            v-if="totBefore != null"
                            class="pb-0 mb-0 font-weight-bold lead"
                        >
                            ${{ totBefore.toFixed(2) }}
                        </p>
                    </div>
                </div>

                <div
                    v-if="promos.length"
                    class="border-bottom mb-2 border-top py-2"
                >
                    <p class="mb-1 lead font-weight-bold">Discounts</p>
                    <div
                        class="d-flex justify-content-between mb-1"
                        v-for="promo in promos"
                        :key="promo.name"
                    >
                        <p class="mb-0 pb-0 w-75">
                            {{ promo.name }}
                        </p>
                        <p class="font-weight-bold text-success mb-0 pb-0">
                            -
                            {{ formatDollarAmount(promo.amount_discounted) }}
                        </p>
                    </div>
                </div>

                <div
                    v-if="nonDiscountableItems.length"
                    class="border-bottom py-1"
                >
                    <div
                        class="d-flex justify-content-between mb-2"
                        v-for="item in nonDiscountableItems"
                        :key="item.price_id"
                    >
                        <h4 class="m-0 p-0 font-weight-normal">
                            {{ item.name }}
                        </h4>
                        <h4 class="m-0 p-0 font-weight-normal">
                            + ${{ item.amount.toFixed(2) }}
                        </h4>
                    </div>
                </div>

                <div>
                    <div class="d-flex justify-content-between mt-2">
                        <p class="pb-0 mb-0 lead font-weight-bold">
                            <span v-if="promos.length">
                                Total After Discounts</span
                            >
                            <span v-else>Total</span>
                        </p>
                        <p
                            v-if="tot != null"
                            class="pb-0 mb-0 lead font-weight-bold"
                        >
                            <template v-if="tot > 0">
                                ${{ tot.toFixed(2) }}
                            </template>

                            <template v-else> FREE </template>
                        </p>
                    </div>
                </div>
            </div>
            <div
                v-if="promoCodesAllowed"
                id="shopCartAccordion"
                class="accordion mt-3"
            >
                <div class="card">
                    <div
                        class="card-collapse"
                        id="shopCartHeadingOne"
                        @click="toggle = !toggle"
                    >
                        <h3 class="mb-0">
                            <a
                                class="custom_button btn-block btn card-btn font-weight-bold collapsed p-4"
                                href="javascript:;"
                                role="button"
                            >
                                Promo code?
                            </a>
                        </h3>
                    </div>

                    <collapse-transition>
                        <div
                            id="shopCartOne"
                            v-show="!toggle"
                            aria-labelledby="shopCartHeadingOne"
                            data-parent="#shopCartAccordion"
                        >
                            <form class="js-validate p-4 border-top">
                                <div class="mb-2">
                                    <div class="input-group input-group-pill">

                                        <input
                                            :class="
                                                promoError ? 'is-invalid' : ''
                                            "
                                            type="text"
                                            class="form-control"
                                            name="name"
                                            placeholder="Promo code"
                                            aria-label="Promo code"
                                            v-model="promoCode"
                                        />
                                        <div class="input-group-append">
                                            <button
                                                @click.prevent="applyPromo()"
                                                class="btn btn-block btn-primary btn-pill"
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                    <span
                                        v-if="promoError"
                                        class="row flex-row mt-0 px-6 text-danger small"
                                        >{{ promoError }}</span
                                    >
                                    <span
                                        v-if="promoSuccess"
                                        class="row flex-row mt-0 px-6 text-success small"
                                        ><i
                                            class="fas fa-check success_icon"
                                        ></i>
                                        Success</span
                                    >
                                </div>
                            </form>
                        </div>
                    </collapse-transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import client from 'braintree-web/client';
import hostedFields from 'braintree-web/hosted-fields';
import paypalCheckout from 'braintree-web/paypal-checkout';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import flyRow from '@/components/fly-form/fly-row';
import submitErrorLog from '@/services/error-logs';
import loading from '@/components/loading';
import Vue from 'vue';

export default {
    components: {
        CollapseTransition,
    },
    props: {
        paymentRequestContext: {
            type: Object,
            required: true,
        },
        paymentRequestData: {
            type: Object,
            required: true,
        },
        paymentComplete: {
            type: Boolean,
            default: false,
        },
        promoCodesAllowed: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        paymentRequestData: {
            deep: true,
            handler(val) {
                this.storeCheckoutData(val);
            },
        },
    },
    data() {
        return {
            hostedFieldInstance: false,
            nonce: '',
            errors: [],
            creditError: '',
            expiryError: '',
            cvvError: '',
            postalCodeError: '',
            context: {},
            toggle: true,
            promoCode: '',
            promoError: '',
            totBefore: null,
            discountedTot: null,
            tot: null,
            items: [],
            paymentFieldsLoading: true,
            promos: [],
            promoSuccess: false,
            paymentMethod: 'credit',
            clientToken: null,
            paypalRendered: false,
        };
    },
    computed: {
        paymentFieldsDisabled() {
            return this.tot <= 0;
        },
        testingPayments() {
            return (
                this.items && this.items.length && this.items[0].price_id == 0
            );
        },
        creditCard() {
            return document.getElementById('creditCardNumber').value;
        },
        expiryDate() {
            return document.getElementById('expiryDate').value;
        },
        cvv() {
            return document.getElementById('cvv').value;
        },
        postalCode() {
            return document.getElementById('postalCode').value;
        },
        state() {
            if (!this.hostedFieldInstance) return null;
            return this.hostedFieldInstance.getState();
        },
        discounts() {
            var discounts = [];
            if (this.context.price_mults) {
                for (var item of this.context.price_mults) {
                    discounts.push({
                        name: item.name,
                        rate: ((1 - item.amt) * 100).toFixed(0),
                    });
                }
            }
            return discounts;
        },

        discountableItems() {
            if (!this.items) return [];

            return this.items.filter(function (item) {
                return item.discountable;
            });
        },
        nonDiscountableItems() {
            return this.items.filter(function (item) {
                return !item.discountable;
            });
        },
    },
    methods: {
        displayVenmoButton(venmoInstance) {
            var venmoButton = document.getElementById('venmo-button');
            // Assumes that venmoButton is initially display: none.
            venmoButton.style.display = 'block';

            venmoButton.addEventListener('click', function () {
                venmoButton.disabled = true;

                venmoInstance.tokenize(function (tokenizeErr, payload) {
                    venmoButton.removeAttribute('disabled');
                });
            });
        },
        isDirty() {
            return true;
        },

        isValid() {
            if (!this.hostedFieldInstance || !this.paypalRendered) {
                return false;
            }
            if (this.paymentFieldsDisabled) {
                return true;
            }
            var valid = true;
            this.postalCodeError = this.creditError = this.expiryError = this.cvvError =
                '';
            var state = this.hostedFieldInstance.getState();
            for (const key in state.fields) {
                if (!state.fields[key].isValid) {
                    if (key == 'number') {
                        this.creditError =
                            'Please enter a valid credit card number';
                        valid = false;
                    }
                    if (key == 'cvv') {
                        this.cvvError = 'Please enter a valid CVV';
                        valid = false;
                    }
                    if (key == 'expirationDate') {
                        this.expiryError = 'Please enter a valid expiry date';
                        valid = false;
                    }
                    if (key == 'postalCode') {
                        this.postalCodeError = 'Postal code invalid';
                        valid = false;
                    }
                }
            }
            return valid;
        },
        async makePayment() {
            if (!this.isValid()) {
                throw 'Cannot make payment when fields are invalid';
            }
            if (this.paymentFieldsLoading) return;
            this.paymentFieldsLoading = true;
            if (this.paymentFieldsDisabled) {
                this.nonce = null;
                return await this.submitPayment();
            }
            this.errors = [];
            this.nonce = '';

            if (this.hostedFieldInstance) {
                var response = await this.hostedFieldInstance.tokenize();
                this.nonce = response.nonce;
                return await this.submitPayment();
            }
        },
        submitPayment() {
            this.paymentFieldsLoading = true;
            return this.$store.getters.client
                .post(`/pay/`, {
                    nonce: this.nonce,
                    ...this.paymentRequestContext,
                })
                .then(() => {
                    this.paymentFieldsLoading = false;
                    this.errors = [];
                    return true;
                })
                .catch((err) => {
                    this.errors.push(err.response.data);
                    this.paymentFieldsLoading = false;
                    return false;
                });
        },
        payAndEmit() {
            this.submitPayment().then((paymentSuccess) => {
                if (paymentSuccess) {
                    this.$emit('payment');
                }
            });
        },
        storeCheckoutData(context) {
            this.items = context.items;
            this.totBefore = context.tot_before;
            this.discountedTot = context.discounted_tot;
            this.promos = context.promos;
            this.tot = context.tot;
            this.promos = context.promos;
        },
        activatePromoSuccess() {
            this.promoSuccess = true;
            this.promoCode = '';
            setTimeout(() => (this.promoSuccess = false), 4000);
        },
        activatePromoFailure(message) {
            this.promoError = message;
        },
        applyPromo() {
            this.$emit('promo-applied', this.promoCode);
            this.promoError = false;
        },

        mountCheckout() {
            if (!this.paymentComplete) {
                client
                    .create({
                        authorization: this.clientToken,
                    })
                    .then((clientInstance) => {
                        let options = {
                            authorization: this.clientToken,
                            client: clientInstance,
                            styles: {
                                input: {
                                    'font-size': '14px',
                                },
                            },
                            fields: {
                                number: {
                                    selector: '#creditCardNumber',
                                },
                                cvv: {
                                    selector: '#cvv',
                                },
                                expirationDate: {
                                    selector: '#expiryDate',
                                },
                                postalCode: {
                                    selector: '#postalCode',
                                },
                            },
                        };
                        const self = this;
                        return Promise.all([
                            hostedFields.create(options),
                            paypalCheckout.create({
                                client: clientInstance,
                                function(
                                    paypalCheckoutErr,
                                    paypalCheckoutInstance,
                                ) {
                                    paypalCheckoutInstance.loadPayPalSDK({
                                        currency: 'USD',
                                        intent: 'sale',
                                        style: {
                                            size: 'small',
                                            color: 'gold',
                                            shape: 'pill',
                                            label: 'checkout',
                                            tagline: 'true',
                                        },
                                    });
                                },
                            }),
                            // braintree.venmo.create(
                            // 	{
                            // 		client: clientInstance,
                            // 		// Add allowNewBrowserTab: false if your checkout page does not support
                            // 		// relaunching in a new tab when returning from the Venmo app. This can
                            // 		// be omitted otherwise.
                            // 		// allowNewBrowserTab: false
                            // 	},
                            // 	function(venmoErr, venmoInstance) {
                            // 		if (venmoErr) {
                            // 			console.error("Error creating Venmo:", venmoErr);
                            // 			return;
                            // 		}

                            // 		// Verify browser support before proceeding.
                            // 		// if (!venmoInstance.isBrowserSupported()) {
                            // 		// 	return;
                            // 		// }
                            // 		self.displayVenmoButton(venmoInstance);

                            // 		// Check if tokenization results already exist. This occurs when your
                            // 		// checkout page is relaunched in a new tab. This step can be omitted
                            // 		// if allowNewBrowserTab is false.
                            // 		if (venmoInstance.hasTokenizationResult()) {
                            // 			venmoInstance.tokenize(function(err, payload) {
                            // 				if (err) {
                            // 					self.handleVenmoError(err);
                            // 				} else {
                            // 					self.handleVenmoSuccess(payload);
                            // 				}
                            // 			});
                            // 			return;
                            // 		}
                            // 	}
                            // ),
                        ]);
                    })
                    .then((instances) => {
                        const hostedFieldInstance = instances[0];
                        this.hostedFieldInstance = instances[0];
                        const paypalCheckoutInstance = instances[1];
                        this.paymentFieldsLoading = false;
                        const self = this;
                        paypalCheckoutInstance.loadPayPalSDK(
                            {
                                currency: 'USD',
                                intent: 'capture',
                            },
                            function () {
                                paypal
                                    .Buttons({
                                        fundingSource: paypal.FUNDING.PAYPAL,

                                        createOrder: function () {
                                            return paypalCheckoutInstance.createPayment(
                                                {
                                                    flow: 'checkout', // Required
                                                    amount: self.tot, // Required
                                                    currency: 'USD', // Required, must match the currency passed in with loadPayPalSDK

                                                    intent: 'capture', // Must match the intent passed in with loadPayPalSDK
                                                },
                                            );
                                        },

                                        onApprove: function (data, actions) {
                                            return paypalCheckoutInstance.tokenizePayment(
                                                data,
                                                function (err, payload) {
                                                    self.errors = [];
                                                    self.nonce = payload.nonce;
                                                    self.payAndEmit();
                                                },
                                            );
                                        },

                                        onCancel: function (data) {},
                                        style: {
                                            size: 'responsive',
                                            color: 'gold',
                                            shape: 'pill',
                                            label: 'checkout',
                                            tagline: 'false',
                                        },

                                        onError: function (err) {
                                            console.error('PayPal error', err);
                                        },
                                    })
                                    .render('#paypalButton')
                                    .then(function () {
                                        self.paypalRendered = true;

                                        // The PayPal button will be rendered in an html element with the ID
                                        // `paypal-button`. This function will be called when the PayPal button
                                        // is set up and ready to be used
                                    });
                            },
                        );
                    })
                    .catch((err) => {
                        this.errors.push(err.message);
                    });
            }
        },
    },
    created() {
        Vue.component('fly-row', flyRow);
        this.storeCheckoutData(this.paymentRequestData);
    },
    mounted() {
        if (!this.paymentComplete) {
            if (this.$store.getters.paymentClientToken) {
                this.clientToken = this.$store.getters.paymentClientToken;
                this.mountCheckout();
            } else {
                return this.$store
                    .dispatch('fetchPaymentClientToken')
                    .then((clientToken) => {
                        this.clientToken = clientToken;
                        this.mountCheckout();
                    });
            }
        } else {
            this.paypalRendered = true;
            this.paymentFieldsLoading = false;
        }
    },
};
</script>

<style scoped>
.custom_button {
    color: black !important;
}

.custom_button:hover {
    color: #ffa51f !important;
}

.payment {
    height: 35px;
}

.visa {
    height: 25px;
}

.const_width {
    width: 20px !important;
}

.venmo_color {
    background-color: #3d95ce !important;
    height: 45px;
    width: 350px;
}

.success_icon {
    padding-top: 3px;
    margin-right: 5px;
}

@media only screen and (max-width: 768px) {
    .grid_dash {
        display: grid;
        gap: 2%;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 1fr;
        grid-template-areas:
            'order_summary'
            'accordion'
            'checkoutForm';
        margin-bottom: 10%;
    }
}

/* iphone x portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    /* STYLES GO HERE */
    .cardNum {
        display: flex;
        flex-direction: column;
    }
}

/* iphone 5 & 5s portrait */
@media only screen and (max-device-width: 568px) and (orientation: portrait) {
    /* STYLES GO HERE */
    .cardNum {
        display: flex;
        flex-direction: column;
    }
}
</style>
